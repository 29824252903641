import React from "react"


import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { AccessHeadline } from "components/pages/access/headline"
import { AccessList } from "components/pages/access/list"
import { AccessFuturePlan } from "components/pages/access/future-plan"
import { graphql, useStaticQuery } from "gatsby"

const Access = ({ path, pageContext, location }) => {
	const data = useStaticQuery(GET_ACCESS_PAGE)
	const lang = pageContext.langKey
	const {
		cover,
		access,
		headline,
		futureAccessPlan,
	} = lang === "en"
			? data?.wordPress?.page?.accessLandingPage
			: data?.wordPress?.page?.translation?.accessLandingPage
			|| data?.wordPress?.page?.accessLandingPage


	const { seo, backgroundColor } = lang === "en"
		? data.wordPress.page
		: data.wordPress.page.translation
		|| data.wordPress.page

	const bgColor = backgroundColor?.backgroundColor?.color
	const SEODefaultImg = cover.image.sourceUrl


	return (
		<Layout path={path} theme={bgColor || "light"} lang={lang}>
			<SEO
				title={seo.title}
				desc={seo.metaDesc}
				fbImg={SEODefaultImg}
				fbTitle={seo.opengraphTitle}
				fbDesc={seo.opengraphDescription}
				url={location.href}
				twitterTitle={seo.twitterTitle}
				twitterDesc={seo.twitterDescription}
				twitterImg={SEODefaultImg}
			/>
			<GlobalCover data={cover} theme={bgColor || "light"} breadcrumb="home/Access" />
			<AccessHeadline data={headline} className="py-5" />
			<AccessList data={access.list} />
			<AccessFuturePlan data={futureAccessPlan} />
		</Layout>
	)
}


export default Access

const GET_ACCESS_PAGE = graphql`
query Access {
	wordPress {
		page(id: "access", idType: URI) {
			backgroundColor {
				backgroundColor {
				  color
				}
			}
			seo {
				title
				metaDesc
				twitterTitle
				twitterDescription
				opengraphTitle
				opengraphDescription
			}
			accessLandingPage {
				cover {
					title
					image {
						sourceUrl
						altText
					}
				}
				headline {
					title
					text
				}
				access {
					list {
						text
						image {
							sourceUrl
							altText
						}
						url
					}
				}
				futureAccessPlan {
					title
					text
					list {
						text
					}
				}
			}
			translation(language: ID) {
				backgroundColor {
					backgroundColor {
					  color
					}
				}
				seo {
					title
					metaDesc
					twitterTitle
					twitterDescription
					opengraphTitle
					opengraphDescription
					}
				accessLandingPage {
					cover {
						title
						image {
							sourceUrl
							altText
						}
					}
					headline {
						title
						text
					}
					access {
						list {
							text
							image {
								sourceUrl
								altText
							}
							url
						}
					}
					futureAccessPlan {
						title
						text
						list {
							text
						}
					}
				}
			}
		}	
	}
}
`