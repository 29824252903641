import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Card } from "components/anti/card/card"

export const AccessList = ({ data }) => {
    const [trigger, anim] = useScrollAnim()

    return (
        <section className="sc-access-list" ref={trigger}>
            <div className="row">
                {data.map((access, i) => {
                    return (
                        <div className="col-lg-4 px-0" key={i}>
                            <Card
                                // onClick={() => movePage(access.text)}
                                variant="overlay"
                                img={access?.image?.sourceUrl}
                                imgAlt={access?.image?.altText ? access?.image?.altText : "Image"}
                                imgRatio="r-16-9"
                                title={access?.text}
                                link={access?.url}
                                className={`access-card ${anim(1 + i)}`}
                                titleClassName="title-icon w-100"
                            />
                        </div>
                    )
                })}
            </div>
        </section>
    )
}