import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"


export const AccessFuturePlan = ({ data }) => {
    const [trigger, anim] = useScrollAnim()

    return (
        <section className="py-main" ref={trigger}>
            <div className="container">
                <h2 className={`${anim(1)}`}>{data.title}</h2>
                <p className={`${anim(2)}`}>{data.text}</p>
                <ul>
                    {data.list.map((li, i) => {
                        return (
                            <li key={i} className={`${anim(3 + i)}`}>
                                {li.text}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </section>
    )
}