import React from 'react'
import { useScrollAnim } from "src/components/hooks/hooks"


export const AccessHeadline = ({ data, className, wrapperClassName }) => {
    const [trigger, anim] = useScrollAnim()

    return (
        <section className={`${className}`} ref={trigger}>
            <div className={`container ${wrapperClassName}`}>
                <h2 className={`${anim(1)}`}>{data?.title}</h2>
                <p className={`${anim(2)}`}>{data?.text}</p>
            </div>
        </section>
    )
}